.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .text {
    position: absolute;
    width: 200px;
    margin-left: -100px;
    text-align: center;
  }
}

$animation-speed: 0.5s;
$animation-mlpt: 4;

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
  background-color: var(--primary-color);
  color: var(--primary-color);
  box-shadow: 9999px 0 0 -5px;
  -webkit-animation: dot-pulse #{$animation-speed * $animation-mlpt} infinite linear;
  animation: dot-pulse #{$animation-speed * $animation-mlpt} infinite linear;
  -webkit-animation-delay: $animation-speed;
  //-webkit-animation-delay: 0;
  animation-delay: $animation-speed;
  //animation-delay: 0;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    background-color: var(--primary-color);
    color: var(--primary-color);
  }

  &::before {
    box-shadow: 9984px 0 0 -5px;
    -webkit-animation: dot-pulse-before #{$animation-speed * $animation-mlpt} infinite linear;
    animation: dot-pulse-before #{$animation-speed * $animation-mlpt} infinite linear;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }

  &::after {
    box-shadow: 10014px 0 0 -5px;
    -webkit-animation: dot-pulse-after #{$animation-speed * $animation-mlpt} infinite linear;
    animation: dot-pulse-after #{$animation-speed * $animation-mlpt} infinite linear;
    -webkit-animation-delay: #{$animation-speed * 2};
    animation-delay: #{$animation-speed * 2};
  }
}

$size-min: -5px;
$size-max: 2px;
$before-offset: 9984px;
@-webkit-keyframes dot-pulse-before {
  0% {
    box-shadow: $before-offset 0 0 $size-min;
  }
  30% {
    box-shadow: $before-offset 0 0 $size-max;
  }
  60%,
  100% {
    box-shadow: $before-offset 0 0 $size-min;
  }
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: $before-offset 0 0 $size-min;
  }
  30% {
    box-shadow: $before-offset 0 0 $size-max;
  }
  60%,
  100% {
    box-shadow: $before-offset 0 0 $size-min;
  }
}

@-webkit-keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 $size-min;
  }
  30% {
    box-shadow: 9999px 0 0 $size-max;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 $size-min;
  }
}

@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 $size-min;
  }
  30% {
    box-shadow: 9999px 0 0 $size-max;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 $size-min;
  }
}

@-webkit-keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 $size-min;
  }
  30% {
    box-shadow: 10014px 0 0 $size-max;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 $size-min;
  }
}

@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 $size-min;
  }
  30% {
    box-shadow: 10014px 0 0 $size-max;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 $size-min;
  }
}

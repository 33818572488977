// additional variables (not in theme)
:root {
  --white: #ffffff;

  --gray-100: #f3f3f3;
  --gray-200: #e5e7eb;
  --gray-400: #9ca3af;
  --gray-600: #4b5563;
  --gray-800: #1f2937;
  --gray-900: #111827;


  --color-success: #439446;
  --color-success-light: #c8e6c9;
  --color-error: #f44336;
  --color-error-light: #ffcdd2;
  --color-warn: #fbc02d;
  --color-warn-light: #ffecb3;
  --color-info: #0891cf;
  --color-info-light: #b3e5fc;


  // Corporate Colors

  --ci-blue: #3f8da4ff;
  --ci-blue-100: #F5F7F8;
  --ci-blue-400: #2E6779;
  --ci-green: #0e9b6aff;
  --ci-orange: #f39724ff;

  --ci-color-1: var(--ci-blue);
  --ci-color-1-dark: var(--ci-blue-400);
  --ci-color-2: var(--ci-green);
  --ci-color-3: var(--ci-orange);

  --ci-surface: var(--ci-blue-100);
  --ci-border-radius: 0.8em;
  --ci-border-color: var(--gray-100);

  --primary-color: var(--ci-color-1);
  --primary-font-color: var(--white);

  --secondary-color: #5c5c5c;
  --secondary-color-dark: #525252;
  --secondary-color-light: #717171;
  --secondary-color-lighter: #a4a4a4;

  --ci-font-color: var(--gray-800);
  --ci-disabled-color: var(--gray-600);
}

h1, h2, h3, h4, h5, h6, p, label {
  color: var(--ci-font-color);
}

.field-checkbox {
  display: flex;
  align-items: center;
}

.color-primary {
  color: var(--primary-color);
}

.color-error {
  color: var(--color-error);
}

.color-warning {
  color: var(--color-warn);
}

.color-success {
  color: var(--color-success);
}

.color-disabled {
  color: var(--gray-800);
}

.scroll-area {
  overflow: auto;
  scrollbar-gutter: both-edges;
  //padding-bottom: 3rem;
}

.no-wrap {
  white-space: nowrap;
}

.mobile-width {
  width: 300px;
}

a {
  color: var(--ci-font-color);
  text-decoration: none;

  &:visited {
    color: var(--ci-font-color);
  }

  &.p-button {
    text-decoration: none;
    color: #fff;
    font-style: normal;
  }
}

p {
  a {
    text-decoration: underline;
    font-style: italic;
  }
}

.white-space-pre-line {
  white-space: pre-line;
}

.margin-center-horizontally {
  margin: 0 auto;
}

//adds pointer to all angular links
[routerlink] {
  cursor: pointer;
}

//scrollbar styling
/* width */
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bebebe;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #989898;
}

::-webkit-scrollbar-button {
  background: #bebebe;
}

::-webkit-scrollbar-button:horizontal {
  width: 0.1rem;
  height: 0.5rem;
}

::-webkit-scrollbar-button:vertical {
  width: 0.5rem;
  height: 0.1rem;
}

p-dynamicdialog {
  .p-dialog-mask {
    //adds mask although if it is not dismissible
    background: var(--p-mask-background);

    //fixes not working pointer on mask
    &.p-overlay-mask {
      cursor: pointer;
      pointer-events: auto !important;
    }

    .p-dialog-content {
      cursor: auto;
    }
  }

  .custom-dialog {
    margin: 0 1rem;
    max-width: 768px;
    width: 100vw;

    //@media only screen and (min-width: 768px) {
    //
    //}

    &.fixed-height {
      max-height: 90vh;
      height: 768px;
    }

    &.full-height {
      height: 100%;
    }

    &.full-screen {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: auto;
      display: flex;
      height: auto;
      max-width: none;
      max-height: none;
      padding: 0;
      margin: 0;

      .p-dialog-header {
        display: none;
      }

      .p-dialog-content {
        padding: 0;
      }
    }

    &.p-dialog > .p-dialog-content {
      margin: 0;
      padding: 0;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      > * {
        flex: 1;
        overflow: hidden;
        display: flex;
      }

      kfd-dialog-layout {
        display: flex;
        flex-direction: column;
        max-height: 100%;
        max-width: 100%;
        flex: 1;
      }
    }
  }
}


//table
table {
  margin: 0;
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  display: table;
  border-collapse: collapse;

  &.no-border {
    box-shadow: none;
  }

  &.width-auto {
    width: auto;
  }

  &.no-margin-x {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  &.no-margin-y {
    margin-top: -0.2rem;
    margin-bottom: -0.2rem;
  }

  .center {
    text-align: center;
  }

  td {
    padding: 0.2rem 0.5rem;
  }

  thead {
    tr {
      background: var(--secondary-color);
      color: var(--text-color-secondary);
    }
  }

  tbody {
    tr {
      display: table-row;

      &:nth-of-type(odd) {
        background: var(--gray-200);
      }
    }
  }
}

.full-width {
  width: 100%;
}

.content-limiter {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
  max-width: 768px;
  //margin: 0 auto;
  overflow: hidden;
}

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

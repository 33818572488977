.p-flex-1 {
  flex: 1;
}

.p-grid {
  form {
    width: 100%;
  }
}

.grid-wrapper {
  // avoid scrolling in grids
  overflow-x: hidden;
  //.grid {
  //  margin-right: 0;
  //  margin-left: 0;
  //}
}

p-toolbar {
  display: block;

  .p-toolbar {
    border-radius: 0;

    &.no-border {
      border: none;
    }

    &.border-top {
      border-bottom: none;
      border-left: none;
      border-right: none;
    }

    &.no-background {
      background: none;
    }

    &.invisible {
      border: 0;
      background: none;
    }

    &.underlined {
      border: none;
      border-bottom: 1px solid var(--secondary-color);
      background: none;
    }

    &.no-padding {
      padding: 0;
    }

    &.no-padding-x {
      padding-left: 0;
      padding-right: 0;
    }

    &.no-padding-y {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.no-padding-top {
      padding-top: 0;
    }

    &.no-padding-bottom {
      padding-top: 0;
    }

    &.fixed-height {
      height: 4rem;
      padding: 0.5rem 1rem;

      .p-button {
        height: 2rem;

        &.p-button-icon-only {
          width: 2rem;
        }
      }
    }

    h1,
    h2,
    h3 {
      margin: 0;
    }
  }
}

p-tag {
  max-width: 100%;

  .secondary {
    background: var(--secondary-color);
    color: var(--text-color-secondary);
  }

  .p-tag {
    max-width: 100%;

    &.outline {
      background: none;
      color: var(--text-color);
      font-weight: normal;

      &.p-tag-info {
        border: 1px solid var(--color-info);
      }
    }

    .p-tag-value {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

p-messages,
p-message {
  //enable margins for messages
  display: block;

  .p-inline-message {
    width: 100%;

    &.small {
      padding: 0.25rem 0.25rem;

      .p-inline-message-text {
        font-size: 0.75rem;
      }
    }
  }

  .p-messages,
  .p-message {
    &.no-margin {
      .p-message {
        margin: 0;
      }
    }

    &.padding-xs {
      .p-message-wrapper {
        padding: 0.25rem;
      }
    }
  }

  &[severity='none'] {
    border: solid var(--surface-700);
    border-width: 0 0 0 3px;
    background: var(--surface-100);
  }
}

.p-field {
  &.disabled {
    color: var(--ci-disabled-color);
  }

  label {
    margin-left: 1em;
  }

  small {
    display: block;
    margin-left: 1em;
    margin-top: 0.25em;

    &.error {
      color: var(--color-error);
    }
  }

  .p-error {
    display: none;
    color: var(--color-error);
  }

  &.invalid {
    .p-error {
      display: block;
    }
  }

  &.valid {
    .p-inputtext {
      border-color: var(--color-success);

      &:focus {
        border-color: var(--color-success);
        box-shadow: 0 0 0 0.2rem var(--green-100);
      }
    }

    .p-checkbox .p-checkbox-box {
      border-color: var(--color-success);
      background: var(--color-success);

      &:focus {
        border-color: var(--color-success);
        box-shadow: 0 0 0 0.2rem var(--green-100);
      }
    }
  }

  + .p-field {
    margin-top: 1.5rem;
  }
}

.field-checkbox {
  //fix unnecessary margins
  margin-bottom: 0;
}

p-card {
  display: block;

  .p-card {
    box-shadow: 0px 0px 2px var(--secondary-color);
    border: 1px solid transparent;

    &.interactive {
      cursor: pointer;

      &:hover {
        box-shadow: 0px 0px 2px var(--primary-color);
        border-color: var(--primary-color);
      }
    }

    &.info-card {
      box-shadow: none;
      background-color: var(--white);

      &.interactive {
        &:hover {
          box-shadow: none;
          border: 1px solid var(--primary-color);
        }
      }
    }

    .p-card-header {
      p-header {
        padding-bottom: 0;
      }
    }

    .p-card-content {
      padding: 0;

      h1,
      h2,
      h3,
      h4 {
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  &.teaser {
    p-header {
      display: block;
      padding: 1rem;

      .card-pre-header {
      }

      .card-title {
        font-size: 1.4rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
        align-items: center;
      }
    }
  }

  &.stretch {
    display: flex;
    height: 100%;

    .p-card {
      flex: 1;
      display: flex;
      align-content: stretch;
      flex-direction: column;

      .p-card-body {
        display: flex;
        align-content: stretch;
        flex-direction: column;
        flex: 1;

        .p-card-content {
          flex: 1;
        }
      }
    }
  }

  // do we really need this (use stretch instead)?
  &.flex {
    display: flex;
    align-content: stretch;

    .p-card {
      display: flex;
      align-content: stretch;
      flex: 1;

      .p-card-body {
        display: flex;
        align-content: stretch;
        flex-direction: column;
        flex: 1;

        .p-card-content {
          display: flex;
          align-content: center;
          flex: 1;
          align-items: center;
        }
      }
    }

    &.centered {
      .p-card {
        .p-card-body {
          align-items: center;
        }
      }
    }
  }
}

.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label {
  top: 0 !important;
  background: white;
  padding: 0 0.5rem;
}

.p-inputtext {
  &.no-focus {
    &:focus {
      outline: inherit;
      border: 1px solid var(--primary-color);
      box-shadow: inherit;
    }
  }
}

//p-multiselect {
//  display: flex;
//  flex-direction: column;
//
//  .p-multiselect-label-container {
//    height: 2.5rem;
//
//    .p-chip .p-chip-text {
//      line-height: 1rem;
//    }
//  }
//}

.p-colorpicker-overlay-panel {
  background: #fff;
}

.p-button {
  &.full-width {
    display: flex;

    button {
      flex: 1;
    }
  }

  // adds a minimum with for buttons with short labels
  &.min-width {
    min-width: 15em;
  }

  &.no-focus {
    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }

  &.no-wrap {
    .p-button-label {
      white-space: nowrap;
    }
  }

  &.slim {
    width: auto;
    padding: 0.3rem 0.1rem;
  }

  &.dark {
    .p-button-label,
    .p-button-icon {
      color: var(--white);
    }
  }

  &.p-button-icon-only {
    &.p-button-rounded {
      overflow: visible;

      .p-badge {
        position: absolute;
        top: -0.25rem;
        right: -0.25rem;
      }

      &.p-button-sm {
        height: 1.5rem;
        width: 1.5rem;
        padding: 0.69rem;
        line-height: 0;
        text-align: center;
        background: var(--white);

        .p-button-icon {
          font-size: 0.65rem;
        }

        &:focus,
        &:hover {
          background: var(--primary-color-lighter);
        }
      }
    }
  }

  &.input-group-button {
    border-radius: 0;
  }

  &.input-group-button-end {
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
  }

  //white button
  &.p-button-white {
    background: var(--white);
  }

  // button on black background
  &.white {
    color: var(--white);

    &:focus {
      box-shadow: 0 0 0 0.2rem var(--gray-200);
    }
  }
}

.p-overlaypanel {
  max-height: calc(100vh - 1rem);

  .p-overlaypanel-content {
    max-width: 100vw;
    margin: 0.2rem;
  }

  &.growable {
    .p-overlaypanel-content {
      font-size: 0.8rem;
      max-height: 80vh;
      overflow: auto;
    }
  }

  &.no-padding {
    .p-overlaypanel-content {
      margin: 0;
      padding: 0;
    }
  }

  &.padding-sm {
    .p-overlaypanel-content {
      margin: 0;
      padding: 0.5rem;
    }
  }

  &.transparent {
    opacity: 0.8 !important;
    background: rgba(255, 255, 255, 0.6);
  }

  &.footer {
    .p-overlaypanel-content {
      .footer-button-right {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        background: #fff;
        box-shadow: 0px 0px 1rem #fff;
      }
    }
  }

  &.large-content {
    .p-overlaypanel-content {
      width: 30rem;
    }

    @media only screen and (min-width: 768px) {
      .p-overlaypanel-content {
        width: 50vw;
        max-width: 768px;
      }
    }
  }
}

.p-toast {
  &.custom-tpl {
    .p-toast-message {
      .p-toast-icon-close {
        position: absolute;
        right: 1rem;
        top: 1rem;
      }
    }
  }
}

.p-dialog {
  &.auto-size {
    max-width: 90vw;
  }

  &.full-screen-dialog {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: auto;
    display: flex;
    height: auto;
    max-width: none;
    max-height: none;

    .p-dialog-header {
      display: none;
    }

    .p-dialog-content {
      padding: 0;
    }
  }
}

p-virtualScroller {
  .p-virtualscroller {
    &.item-border {
      .p-virtualscroller-item {
        border-top: 1px solid var(--gray-200);
        border-bottom: 1px solid var(--gray-200);

        + .p-virtualscroller-item {
          border-top: none;
        }
      }
    }
  }
}

p-orderlist {
  --p-listbox-option-border-radius: var(--p-listbox-border-radius);
  --p-listbox-list-padding: 0;
  --p-listbox-list-header-padding: 0;
  --p-listbox-option-padding: 0.125em 1em;

  .p-listbox {
    // fixes bug: scrollbar outside box when using border radius
    overflow: hidden;
  }

  .p-orderlist {

    .p-orderlist-list-container {
      // fixes bug: option list has not full-width
      flex: 1;
      display: flex;
      flex-direction: column;

      p-listbox {
        flex: 1;
        display: flex;
        flex-direction: column;

        .p-listbox {
          flex: 1;
          display: flex;
          flex-direction: column;
        }
      }
    }

    // fixes bug: not working controlsposition option
    @media screen and (min-width: 961px) {
      &.p-orderlist-controls-right {
        flex-direction: row-reverse;
      }
    }
  }

  &.full-height {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;


    .p-orderlist {
      flex: 1;
      overflow: hidden;
      //display: flex;
      //flex-direction: column;

      .p-orderlist-list-container {
        overflow: hidden;
        //display: flex;
        //flex-direction: column;


        p-listbox {
          overflow: hidden;

          .p-listbox {
            overflow: hidden;

            .p-listbox-list-container {
              flex: 1;
              max-height: none !important;
            }
          }
        }
      }
    }
  }
}

.p-listbox {
  &.no-border {
    //.p-listbox-list {
    border: none;
    //}
  }
}

.p-tree {
  &.no-style {
    background: transparent;
    border: none;
    padding: 0;
    color: var(--text-color);
  }

  .disabled {
    color: gray;
  }
}

//p-inputnumber {
//  &.p-inputwrapper {
//    display: flex;
//    flex-direction: column;
//  }
//}

.p-inputgroup {

  .p-inputgroupaddon {
    //allow inputs in p-inputgroupaddon
    &.input {
      padding: 0;
      flex: 1;

      input {
        border: none;
      }
    }
  }

  &.vertical {
    flex-direction: column;

    .p-inputgroupaddon {
      border-block-start: 1px solid var(--p-inputgroup-addon-border-color);
      border-inline-start: 1px solid var(--p-inputgroup-addon-border-color);
      border-inline-end: 1px solid var(--p-inputgroup-addon-border-color);
      border-block-end: none;
      border-start-start-radius: 0;
      border-start-end-radius: 0;
      border-radius: 0;

      &:first-child {
        border-top-left-radius: var(--p-inputgroup-addon-border-radius);
        border-top-right-radius: var(--p-inputgroup-addon-border-radius);
      }

      &:last-child {
        border-block-end: 1px solid var(--p-inputgroup-addon-border-color);
      }

      &.input {
        padding: 0;
      }
    }
  }
}

p-calendar {
  display: flex;
  flex-direction: column;
}

p-dynamicdialog {
  .p-dialog-mask {
    z-index: 1000;
  }
}

p-inputswitch {
  display: flex;

  .p-inputswitch {
    align-self: center;

    &.sm {
      width: 2rem;
      height: 1rem;

      .p-inputswitch-slider:before {
        width: 0.8rem;
        height: 0.8rem;
        left: 0.1rem;
        margin-top: -0.4rem;
      }

      &.p-inputswitch-checked .p-inputswitch-slider {
        &:before {
          transform: translateX(1rem);
        }
      }
    }

    &.secondary {
      &.p-inputswitch-checked .p-inputswitch-slider {
        background: var(--secondary-color);
      }
    }
  }
}

//.p-inputgroup {
//  .p-inputgroup-addon {
//    &.active {
//      color: var(--primary-color);
//      border-color: var(--primary-color);
//    }
//  }
//
//  &.invalid {
//    .p-inputtext {
//      border-color: var(--color-error);
//    }
//  }
//
//  .p-inputtext {
//    //aligns height with buttons
//    height: 2.357em;
//  }
//}

// fixes missing margin on the right site of input elements when using an icon and there is not enough space
.p-input-icon-left > .p-inputtext {
  margin-right: -1rem;
  max-width: 100%;
}

.p-fluid {
  .p-inputtext {
    width: 100%;
    // override to avoid increasing with of dynamic parent elements
    display: flex;
  }

  textarea,
  p-datepicker,
  p-select {
    width: 100%;
  }
}

p-datepicker {
  .p-datepicker {
    //fix: forces full with
    width: 100%;
  }
}

p-menu {
  .p-menu {
    &.invisible {
      background: transparent;
      border: none;
      padding: 0;
    }

    &.auto-width {
      width: auto;
      max-width: 100%;
      overflow: hidden;
      min-width: auto;
    }

    &.side-navigation {
      --p-menu-list-padding: 0;

      .menu-item {
        padding: 0.75em 1em;
      }

      .p-menuitem-icon {
        margin: 0 0.5em;
      }

      .p-menuitem-link-active {
        .p-menuitem-text {
          font-weight: 700;
          color: var(--ci-color-3);
        }

        .p-menuitem-icon {
          color: var(--ci-color-3);
        }
      }

      .p-menuitem-text {
        font-size: 1.2em;
      }

      .p-menu-submenu-label {
        padding: 0;
      }

      .p-menuitem-link {
        &:hover {
          .p-menuitem-icon {
            color: var(--ci-color-1);
          }

          .p-menuitem-text {
            color: var(--ci-color-1);
          }
        }
      }

      &.small {
        .menu-item {
          padding: 0.5em 1em;
          font-size: 0.9em;
        }
      }
    }
  }
}

.p-fileupload .p-fileupload-buttonbar {
  // space between buttons
  display: flex;
  justify-content: space-between;
}

//.p-tabview {
//  &.content-scrollable {
//
//  }
//}

p-stepper {
  &.full-height {
    display: flex;
    flex-direction: column;
    flex: 1;

    p-step-panels {
      display: flex;
      flex-direction: column;
      flex: 1;

      p-step-panel {
        &.p-steppanel-active {
          height: 100%;
          flex: 1;
          display: flex;
          flex-direction: column;
          overflow: hidden;
        }

        .p-stepper-separator {
          flex: none
        }

        .p-steppanel-content {
          flex: 1;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  &.no-separator {
    p-step-panel {
      .p-stepper-separator {
        display: none;
      }
    }
  }
}

p-tabs {
  &.full-height {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    --p-tabs-tabpanel-padding: 0;

    p-tabpanels:has(.p-tabpanel[data-p-active="true"]) {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;

      .p-tabpanel[data-p-active="true"] {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: auto;
        padding: 1em;
      }
    }
  }

  &.surface {
    --p-tabs-tabpanel-background: var(--ci-surface);
    --p-tabs-tablist-background: var(--ci-surface);
    --p-tabs-tab-background: var(--ci-surface);
    //--p-tabs-tab-border-color: blue;
    --p-tabs-tablist-border-color: var(--ci-surface-border-color);
    --p-tabs-tablist-border-width: 0 0 1px 0;
  }
}

//p-tabview {
//  &[styleclass='full-height'] {
//    flex: 1;
//    overflow: hidden;
//  }
//
//  &.no-background {
//    .p-tabview-nav,
//    .p-tabview-nav-link,
//    .p-tabview-panels {
//      background: none;
//    }
//  }
//
//  &.full-height {
//    height: 100%;
//    flex: 1;
//    display: flex;
//    flex-direction: column;
//    overflow: hidden;
//
//    .p-tabpanels {
//      flex: 1;
//      overflow: hidden;
//
//      p-tabpanel {
//        &:has(.p-tabview-panel[aria-hidden="false"]) {
//          height: 100%;
//          width: 100%;
//          display: flex;
//          flex-direction: column;
//          overflow: hidden;
//
//          .p-tabview-panel {
//            flex: 1;
//            display: flex;
//            flex-direction: column;
//            overflow: hidden;
//          }
//        }
//      }
//    }
//  }
//
//  &.custom-header {
//    .p-tabview-nav li .p-tabview-nav-link {
//      padding: 0;
//    }
//  }
//}

.p-overlay-badge {
  // adds small size to badges
  &.badge-small {
    .p-badge {
      font-size: 0.7rem;
      font-weight: 500;
      min-width: 1rem;
      height: 1rem;
      line-height: 0.9rem;
    }
  }

  &.badge-outline {
    .p-badge {
      background: none;
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
    }
  }

  &.badge-pos-70 {
    .p-badge {
      transform: translate(70%, -70%);
    }
  }

  &.badge-pos-80 {
    .p-badge {
      transform: translate(80%, -80%);
    }
  }
}

.p-popover {
  &.allow-full-height {
    max-height: calc(100dvh);
    max-width: calc(100dvw);
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .p-popover-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;
    }
  }
}

@font-face {
  font-family: 'icomoon';
  src: url('./../../../../../assets/icons/fonts/icomoon.eot?7zpvhp');
  src:
    url('./../../../../../assets/icons/fonts/icomoon.eot?7zpvhp#iefix') format('embedded-opentype'),
    url('./../../../../../assets/icons/fonts/icomoon.ttf?7zpvhp') format('truetype'),
    url('./../../../../../assets/icons/fonts/icomoon.woff?7zpvhp') format('woff'),
    url('./../../../../../assets/icons/fonts/icomoon.svg?7zpvhp#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  font-family: 'icomoon' !important;
}

.icon-field-upload:before {
  content: '\e903';
}

.icon-field-action:before {
  content: '\e90a';
}

.icon-field-signature:before {
  content: '\e90b';
}

.icon-condition:before {
  content: '\e909';
}

.icon-entry:before {
  content: '\e901';
}

.icon-group:before {
  content: '\e902';
}

.icon-field-readonly:before {
  content: '\e904';
}

.icon-field-date:before {
  content: '\e905';
}

.icon-field-bool:before {
  content: '\e906';
}

.icon-field-select:before {
  content: '\e907';
}

.icon-field-numeric:before {
  content: '\e908';
}

.icon-field-text:before {
  content: '\e900';
}

.icon-summary:before {
  content: '\e90c';
}

.icon-divide:before {
  content: '\e90e';
}

.icon-math:before {
  content: '\e90d';
}
